<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Stat from "@/components/widgets/stat.vue";

import PADChargeDialog from './components/bill_pad_charge_dialog.vue'
import SaveChequeDialog from './components/bill_cheque_deposit_dialog.vue'
import ETransferDialog from './components/bill_etransfer_deposit_dialog.vue'
import CreditCardChageDialog from './components/bill_credit_card_charge_dialog.vue'

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Choices from "choices.js";

import { getArApApi } from '@/api/cash'
import { financial, autoComplete } from '@/api/misc'

/**
 * User list component
 */
export default {
  page: {
    title: "AR ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "General Account Mgt",
      items: [
        {
          text: "Account Receivable",
          href: "/",
        },
        {
          text: "General Account Mgt",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 200,
      fields: [
        { key: 'G', label: '', tdClass: 'align-middle' },
        {
          key: "Date",
          label: 'Date',
          sortable: true,
          tdClass: 'align-middle'
        },

        
        {
          key: "Type",
          label: 'Type',
          sortable: true,
          tdClass: 'align-middle'
        },


        {
          key: "Agent",
          label: 'Payor',
          sortable: true,
          tdClass: 'align-middle'
        },

        { key: 'Invoice', tdClass: 'align-middle' },
       

        {
          key: "Amount",
          sortable: true,
          tdClass: 'align-middle'
        },

        {
          key: "PayMethod",
          label: 'PayMethod',
          sortable: true,
          tdClass: 'align-middle'
        },

        {
          key: "Paid",
          sortable: true,
          tdClass: 'align-middle'
        },



        {
          key: "Action",
          sortable: false,
        },

      ],

      widgetData: [
        {
          icon: "uil-dollar-alt",
          title: "Balance Total",
          value: '$2123.34',
        },
        {
          icon: "uil-layer-group",
          title: "Total Count",
          value: this.rows,
        },

      ],

      filter: { pay_type: '' },
      agent_filter: '',
      filterOn: ['pay_type', 'agent'],
      sortBy: "",
      sortDesc: false,
      current_show_type: '',
      current_ar: {},
      selected_bills: [],
      filter_bill_list: [],
      total_amount: 0,
      search_str: '',
      searched_data: [],
      statement_range : '',
      status_filter : 'PENDING',
      type_filter   : 'BILL',
    };
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    SaveChequeDialog,
    PADChargeDialog,
    ETransferDialog,
    CreditCardChageDialog,
    VueBootstrapTypeahead,
    flatPickr
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
    cheque_btn_disabled_flag() {
      if (this.selected_bills.length <= 0 || this.selected_bills.length > 1) {
        return true
      }

      return false;
    },

    eft_btn_disabled_flag() {
      if (this.selected_bills.length <= 0) {
        return true
      } else {
        let eft_support = this.selected_bills.filter(b => b.agent.charge_type == 'PAD')

        return eft_support.length != this.selected_bills.length
      }
    },

    credit_card_btn_disabled_flag() {
      if (this.selected_bills.length <= 0) {
        return true
      } else {
        let eft_support = this.selected_bills.filter(b => b.agent.charge_type == 'CREDIT_CARD')

        return eft_support.length != this.selected_bills.length
      }
    }

  },

  created() {
    this.queryList('', '', 0)
  },
  
  mounted() {

    new Choices('#type_filter')
    new Choices('#status_filter')
  },
  methods: {

    f(v, d = 2) {
      return financial(v, d)
    },

    queryList(query_agent, invoice_number, finish_flag) {
      let data = {
        page: {
          page_no: this.currentPage,
          page_size: this.perPage
        }
      }

      if (query_agent) {
        data.query_agent = query_agent
      }

      if (invoice_number) {
        data.invoice = invoice_number
      }


      if (finish_flag || finish_flag == 0) {
        data.is_finish_flag = finish_flag
      }


      getArApApi().agent_bill_query(data).then((res) => {
        this.list = []
        res.data.map((o) => {
          o.checked = false
          o.amount  = o.amount - (o.received_amount ||0)
          this.list.push(o)

        })
        this.totalRows = res.page.total;
        this.widgetData[1].value = this.totalRows
      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList('', '', 0)
    },



    onDeposit(dialog_id) {
      this.selected_bills = []
      this.list = this.list.filter(e => e.checked == false)
      this.$bvModal.hide(dialog_id)

      if (dialog_id == 'modal-credit-card-charge-dialog' || dialog_id == 'modal-pad-charge-dialog') {
        this.$router.push({ name: 'ar-pad-list' })
      }
    },



    onSelectedBill(evt, bill) {
      if (evt.target.checked == true) {
        let d = this.selected_bills.find((e) => e.id == bill.id)
        if (!d) {
          this.selected_bills.push(bill)
        }
      } else {
        this.selected_bills = this.selected_bills.filter((e) => e.id != bill.id)
      }

      bill.checked = evt.target.checked

    },

    onSelectAllBill(evt) {
      this.selected_bills.map(e => e.checked = false)
      this.selected_bills = []
      if (evt.target.checked == true) {
        this.selected_bills.push(...this.filter_bill_list)
        this.selected_bills.map(e => e.checked = true)
      }
    },

    onFilterStatus() {
      this.queryList('', '', this.status_filter =='PENDING'? 0 : 1)
    },

    filterTable(row, filter) {
      if (filter.pay_type && filter.agent_filter) {
        return row.agent.charge_type == filter.pay_type && row.agent.full_name.startsWith(filter.agent_filter)
      } else if (filter.agent_filter) {
        return row.agent.full_name.startsWith(filter.agent_filter)
      } else if (filter.pay_type) {
        return row.agent.charge_type == filter.pay_type
      } else {
        return true
      }
    },

    onFiltered(filteredItems) {
      this.filter_bill_list = filteredItems
      this.total_amount = financial(filteredItems.reduce((a, c) => a + Number(c.amount) + Number(c.tax), 2))
    },

    onFilterChargeType(evt, ty) {

      if (evt.target.checked == false) {

        this.current_show_type = ''
        this.filter = { pay_type: '' }
      } else {
        this.current_show_type = ty
        this.filter = { pay_type: ty }
      }
    },

    list_sort() {
      this.list.sort((a, b) => {
        if (this.sortBy == 'Amount') {
          if (this.sortDesc) {
            return a.amount > b.amount ? -1 : 1
          } else {
            return a.amount > b.amount ? 1 : -1
          }
        }
      })
    },

    getFootValue(item) {

      if (item.label == 'Amount') {
        return '$' + this.total_amount.toLocaleString()
      } else if (item.label == 'Post Date') {
        return 'Total: '
      } else {
        return ''
      }

    },

    onSearch(txt) {

      if (txt == '') {
        this.queryList()
        return;
      }

      this.searched_data = []
      autoComplete({ types: ['INVOICE', 'AGENT'], str: txt }).then(res => {
        if (res.errCode == 0) {
          res.data.map(e => {
            this.searched_data.push({
              str_id: e.str_id,
              s_type: e.s_type,
              obj: e
            })
          })

        }
      })
    },



    onSearchedSelected(evt) {
      if (evt.s_type == 'AGENT') {
        this.queryList(evt.obj.id)
      } else {
        this.queryList('', evt.str_id)
      }
    }

  },

  watch: {
    sortBy() {
      this.list_sort()
    },

    sortDesc() {
      this.list_sort()
    },

    agent_filter(new_val) {

      let cache = this.filter
      this.filter = { pay_type: cache.pay_type, agent_filter: new_val }
    },
    search_str(search_str) {
      this.onSearch(search_str)
    },
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal centered id="modal-pad-charge-dialog" title="PAD Charge" size="lg" hide-footer>
      <PADChargeDialog :bills="selected_bills" @cancel="$bvModal.hide('modal-pad-charge-dialog')"
        @confirm="onDeposit('modal-pad-charge-dialog')" />
    </b-modal>

    <b-modal centered id="modal-bill-cheque-deposit-dialog" title="Deposit Cheque" size="lg" hide-footer>
      <SaveChequeDialog :bills="selected_bills" @cancel="$bvModal.hide('modal-bill-cheque-deposit-dialog')"
        @confirm="onDeposit('modal-bill-cheque-deposit-dialog')" />
    </b-modal>

    <b-modal centered id="modal-bill-etransfer-deposit-dialog" title="Deposit Cheque" size="lg" hide-footer>
      <ETransferDialog :bills="selected_bills" @cancel="$bvModal.hide('modal-bill-etransfer-deposit-dialog')"
        @confirm="onDeposit('modal-bill-etransfer-deposit-dialog')" />
    </b-modal>
    <b-modal centered id="modal-credit-card-charge-dialog" title="Credit Card Charge" size="lg" hide-footer>
      <CreditCardChageDialog :bills="selected_bills" @cancel="$bvModal.hide('modal-credit-card-charge-dialog')"
        @confirm="onDeposit('modal-credit-card-charge-dialog')" />
    </b-modal>


    <div class="row">
      <Stat :widgetData="widgetData" />
    </div>
    <div class="card">
      <div class="card-header">
        <div class="row mb-4">
          <div class="col-md-2">
            <select id="type_filter">
              <option value="BILL" selected>Bill</option>
              <option value="OTHER">Other</option>
            </select>
          </div>
          <div class="col-md-2">
            <select id="status_filter" v-model="status_filter" @change="onFilterStatus">
              <option value="PENDING" selected>Pending</option>
              <option value="COMPLETED">Completed</option>
            </select>
          </div>
          <div class="col-md-3">
            <vue-bootstrap-typeahead ref="supplier_name_ref" v-model="search_str" :data="searched_data"
              :serializer="s => s.s_type == 'INVOICE' ? 'Invoice:' + s.str_id : 'Agent:' + s.str_id"
              placeholder="Search by Agent or Invoice" :foramterDisplay="s => s.str_id"
              @hit="onSearchedSelected($event)" autocomplete="off" />

          </div>


          <div class="col-md-3">
            <flat-pickr v-model="statement_range" :config="{ mode: 'range' }" placeholder="query by date range"
              class="form-control"></flat-pickr>
          </div>


          <div class="col-md-2 mt-2">
            <div class="row">
              <div class="col-md-4  d-flex flex-wrap gap-2">
                <input type="checkbox" class="form-check-control" @change="onFilterChargeType($event, 'PAD')"
                  :checked="current_show_type == 'PAD'" />PAD
              </div>
              <div class="col-md-7  d-flex flex-wrap gap-2">
                <input type="checkbox" class="form-check-control" @change="onFilterChargeType($event, 'CREDIT_CARD')"
                  :checked="current_show_type == 'CREDIT_CARD'" />Credit Card
              </div>
            </div>
          </div>

        </div>
        <!-- end row -->

        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2">
            <b-button variant="primary" :disabled="credit_card_btn_disabled_flag"
              v-b-modal.modal-credit-card-charge-dialog>Credit Card</b-button>
            <b-button variant="primary" :disabled="eft_btn_disabled_flag"
              v-b-modal.modal-pad-charge-dialog>PAD</b-button>
            <b-button variant="primary" :disabled="cheque_btn_disabled_flag"
              v-b-modal.modal-bill-cheque-deposit-dialog>Deposit Cheque</b-button>

            <b-dropdown split text="More" variant="light">
              <b-dropdown-item @click="$router.push({ name: 'ar-charge-agent-fee' })">Charge Agent</b-dropdown-item>
              <b-dropdown-item @click="$router.push({ name: 'ar-batch-charge-agents' })">Batch Charge Agent</b-dropdown-item>
              <b-dropdown-item @click="$router.push({ name: 'ar-deposit-cheque' })">Add Receible</b-dropdown-item>
              <b-dropdown-item @click="$router.push({ name: 'ar-deposit-cheque' })">Load All</b-dropdown-item>
            </b-dropdown>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->

      </div>

      <div class="card-body">
        <div class="table-responsive">
          <div class="table align-middle table-nowrap">
            <b-table :items="list" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
              :filter-function="filterTable" class="table-check datatables" striped hover foot-clone>


              <template #head(G)>
                <input type="checkbox" @change="onSelectAllBill($event)" />
              </template>




              <template #cell(G)="data">
                <input type="checkbox" @change="onSelectedBill($event, data.item)" :checked="data.item.checked" />

              </template>

              <template #cell(Date)>
                {{ '2024-06-01' }}
              </template>
              <template #cell(Type)>
                {{ 'AGENT BILL' }}
              </template>

              <template #cell(PayMethod)="data">
                {{ data.item.agent.charge_type }}
              </template>

              <template #cell(Agent)="data">
                <router-link :to="{ name: 'brokerage-agent-view', query: { agent_id: `${data.item.agent_id}` } }"
                  class="text-body fw-medium" target="_blank">{{ `${data.item.agent.full_name}` }}</router-link>
              </template>
              <template #cell(Invoice)="data">
                {{ data.item.invoice_number }}
              </template>

              <template #cell(Month)="data">
                {{ data.item.stmt_month }}
              </template>
              <template #cell(Amount)="data">
                ${{ f((data.item.amount + data.item.tax)).toLocaleString() }}
              </template>

              <template #cell(Paid)="data">
                <b-badge pill class="badge-soft-dark me-1"  v-if="data.item.is_finish_flag == 1">
                  {{ 'Paid' }}
                </b-badge>
                <b-badge pill class="badge-soft-primary me-1" v-else>
                  {{ 'Pending' }}
                 
                </b-badge>

              </template>


              <template #cell(action)="data">
                <b-dropdown variant="light" right size="sm">
                  <template v-slot:button-content>
                    <i class="uil uil-ellipsis-h"></i>
                  </template>

                  <b-dropdown-item
                    @click="$router.push({ name: 'ar-agent-bill-detail', query: { invoice_number: data.item.invoice_number, adjust: true } })"
                    :disabled="data.item.is_finish_flag == 1">Adjust</b-dropdown-item>
                  <b-dropdown-item
                    @click="$router.push({ name: 'ar-agent-bill-detail', query: { invoice_number: data.item.invoice_number, view: true } })">View</b-dropdown-item>
                </b-dropdown>
              </template>

              <template #foot()="data">
                <i>{{ getFootValue(data) }}</i>
              </template>


            </b-table>
          </div>
        </div>
        <!-- end table responsive -->

        <div class="row g-0 text-center text-sm-start">
          <!-- end col -->
          <div class="col-sm-12">
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <!-- pagination -->
                  <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                    :per-page="perPage" @change="pageChange"></b-pagination>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->

  </Layout>
</template>
